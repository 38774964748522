import 'babel-polyfill';
import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import '../styles/main.scss';
import logo from '../img/Logo.png';
import reverseLogo from '../img/Logo.png';
import Hamburger from './Hamburger';

const Layout = ({ children }) => (
	<div id="outer-wrap">
		<Helmet>
			<html lang="en" />
			<title>GridZeroSolar | Home</title>
			<meta name="description" content="GridZero Solar Website." />
		</Helmet>
		<nav className="mobile-nav is-hidden-desktop is-hidden-tablet has-text-right">
			<Link to="/">
				<img
					src={reverseLogo}
					alt="GridZeroSolar Logo"
					className="is-hidden-desktop is-hidden-tablet"
					style={{
						position: 'absolute',
						left: '1rem',
						height: '4rem',
					}}
				/>
			</Link>
			<Hamburger />
		</nav>
		<div id="page-wrap">
			<div style={{ position: 'fixed', width: '100%', zIndex: 10 }}>
				<nav className="top-nav is-muted is-hidden-mobile">
					<div className="container">
						<Link to="/">
							<img
								src={logo}
								alt="GridZeroSolar Logo"
								style={{
									position: 'absolute',
									left: 0,
									height: '98px',
									top: 13,
									backgroundColor: '#0291bf',
								}}
							/>
						</Link>
						
					</div>
				</nav>
				<nav className="main-nav is-hidden-mobile">
					<div className="container">
						<ul>
							<li>
								<Link to="/residential">Residential</Link>
							</li>
							<li>
								<Link to="/contact">Net Meter</Link>
							</li>
							<li>
								<Link to="/off-grid">Off Grid</Link>
							</li>
							<li>
								<Link to="/faq">FAQ</Link>
							</li>
							<li>
								<Link to="/contact">Contact</Link>
							</li>
						</ul>
					</div>
				</nav>
			</div>
			{children}
			<footer className="top-nav is-dark">
				<div className="container has-text-centered">
					<p style={{ padding: '0.75rem', margin: 0 }}>
						<small>
							&copy; Copyright 2019 Grid Zero Solar. All Rights Reserved.
						</small>
					</p>
				</div>
			</footer>
		</div>
	</div>
);

export default Layout;

